import { Controller } from "stimulus";
import { EventCurrencyChanged, EventPriceChanged, EventPriceLoaded } from "../../events";
import { getActiveCurrency, formatCurrency } from "../../util/currency";

const EXPONENT_CONFIG = { significantFigures: 4, maximumDecimalTrailingZeroes: 4 }
// This controller is responsible for executing price changes from websocket or user actions.
// For performance reasons, there is only ONE price controller in the entire page.
export default class extends Controller {
  // DOM should have data-price-target="price" in order to refer to elements that need price update.
  static targets = ["price"];

  // A 1:N map between coinId and targets for performance due to quicker lookups.
  coinIdTargets = {};

  connect() {
    // Register each target into the coinId-target map.
    this.priceTargets.forEach(target => {
      const coinId = +target.getAttribute("data-coin-id");
      if (coinId) {
        this.coinIdTargets[coinId] ||= []
        this.coinIdTargets[coinId].push(target)
      }
    });
    Object.freeze(this.coinIdTargets);


    // Update price whenever we receive an event from websockets.
    window.addEventListener(EventPriceChanged, e => this._updatePrice(e, true));

    // Update price whenever the user changes the currency, and on initial page load.
    window.addEventListener(EventCurrencyChanged, e => this._updatePrice(e));

    // Update price whenever price is dynamically added into DOM (e.g. AJAX rendering).
    window.addEventListener(EventPriceLoaded, e => this._updatePrice(e));
  }

  _updatePrice(e, isLiveUpdate = false) {
    const coinId = e?.detail?.coinId;
    const currencyCode = e?.detail?.currencyCode || getActiveCurrency();
    const targets = coinId ? this.coinIdTargets[coinId] : this.priceTargets;

    targets?.forEach(priceTarget => {
      const symbol = priceTarget.getAttribute("data-coin-symbol");

      // Three possible sources of data, "data-price-btc" or "data-price-json" or "data-price-usd".
      let price;
      if (currencyCode === symbol) {
        // The price of ABC coin in ABC currency is 1.
        price = 1;
      } else if (priceTarget.hasAttribute("data-price-usd")) {
        // If event provided price, use it. Otherwise, use the existing price.
        const eventPriceUSD = e?.detail?.priceUSD;
        const priceUSD = eventPriceUSD || +priceTarget.getAttribute("data-price-usd");

        // If provided, store price from event for future updates.
        if (eventPriceUSD) {
          priceTarget.setAttribute("data-price-usd", eventPriceUSD);
        }

        price = fx(priceUSD).from("usd").to(currencyCode);
      } else if (priceTarget.hasAttribute("data-price-btc")) {
        // If event provided price, use it. Otherwise, use the existing price.
        const eventPriceBTC = e?.detail?.priceBTC;
        const priceBTC = eventPriceBTC || +priceTarget.getAttribute("data-price-btc");

        // If provided, store price from event for future updates.
        if (eventPriceBTC) {
          priceTarget.setAttribute("data-price-btc", eventPriceBTC);
        }

        price = fx(priceBTC).from("btc").to(currencyCode);
      } else if (priceTarget.hasAttribute("data-price-json")) {
        // Only update when triggered from currency change event.
        // Other events update priceBTC, which is not applicable here.
        if (!e?.detail?.currencyCode) {
          return;
        }

        // Get JSON price from attributes.
        const priceJSON = JSON.parse(priceTarget.getAttribute("data-price-json"));
        price = priceJSON && priceJSON[currencyCode];
      } else {
        // The price element is missing both "data-price-btc" and "data-price-json", skip.
        return;
      }

      // Prevent replacement of server-side text if NaN.
      if (isNaN(price)) {
        return;
      }


      // Update the target's display text with the new price.
      const raw = !!this._normalizeAttributeDataType(priceTarget.getAttribute("data-raw"));
      const noDecimalAttribute = this._normalizeAttributeDataType(priceTarget.getAttribute("data-no-decimal"));
      // Only applies transformation of decimal trailing zeroes to exponent for small numbers
      const noDecimal = this._getNoDecimal(noDecimalAttribute, price);
      const abbreviated = !!this._normalizeAttributeDataType(priceTarget.getAttribute("data-abbreviated"));
      const prevDisplayText = priceTarget.textContent.trim();
      const nextDisplayText = formatCurrency(price, currencyCode, raw, noDecimal, abbreviated);

      priceTarget.innerHTML = nextDisplayText;

      // Blink only if the displayed price actually changed.
      if (isLiveUpdate && prevDisplayText !== nextDisplayText) {
        const prevPrice = +priceTarget.getAttribute("data-prev-price");

        if (price >= prevPrice) {
          priceTarget.classList.add("gecko-up");
          priceTarget.classList.remove("gecko-down");
        } else {
          priceTarget.classList.add("gecko-down");
          priceTarget.classList.remove("gecko-up");
        }

        // Remove color after delay
        setTimeout(() => priceTarget.classList.remove("gecko-up", "gecko-down"), 1000);
      }

      // Store current price in attribute for future live update comparison.
      priceTarget.setAttribute("data-prev-price", price);
    });
  }

  _normalizeAttributeDataType(attribute) {
    if (attribute === null) {
      return null;
    } else if (attribute === "false") {
      return false;
    } else if (attribute === "true") {
      return true;
    } else {
      try {
        return JSON.parse(attribute);
      } catch(ex){
        return attribute;
      }
    }
  }

  _getNoDecimal(noDecimalAttribute, price) {
    if (noDecimalAttribute === null || noDecimalAttribute === "") {
      return (price > 0 && price < 1) ? EXPONENT_CONFIG : false;
    } else if (typeof noDecimalAttribute === "boolean" || typeof noDecimalAttribute === "object") {
      return noDecimalAttribute;
    }

    return false;
  }
}
