export function addStaticDropdownItem({ value, innerHTML = value, attributes = {} }, sortHandler = _sortByValue) {
  if (value === undefined || value == null) {
    throw new Error("Dropdown items must have a valid non-null value!")
  }

  const staticDropdownItem = document.createElement("div");
  staticDropdownItem.setAttribute("x-on:click", "handleSelect");
  staticDropdownItem.setAttribute("data-value", value);
  for (const [key, value] of Object.entries(attributes)) {
    staticDropdownItem.setAttribute(key, value.toString());
  }

  staticDropdownItem.classList.add("gecko-select-dropdown-item");

  staticDropdownItem.innerHTML = innerHTML;

  if (this.value && Array.from(this.value).includes(String(value))) {
    staticDropdownItem.innerHTML += '<i class="fa fa-check gecko-select-selected"></i>';
  }

  const addedItem = this._gp_refDropdownStatic.appendChild(staticDropdownItem);

  if (typeof sortHandler === "function") {
    sortHandler(this._gp_refDropdownStatic);
    Alpine.initTree(addedItem);
  } else {
    throw new Error("Sorting function must be a function!");
  }

  return addedItem;
}

function _sortByValue(parentNode) {
  [...parentNode.children]
    .sort((a, b) => +a.dataset.value - +b.dataset.value)
    .forEach(node => parentNode.appendChild(node));
}

export function removeStaticDropdownItem({ value }) {
  const itemToRemove = this._gp_refDropdownStatic.querySelector(`[data-value="${value}"]`);

  if (itemToRemove) {
    this._gp_refDropdownStatic.removeChild(itemToRemove);
    if (+this.value === value) {
      this._gp_refDropdownStatic.firstElementChild?.click();
    }
  } else {
    throw new Error(`No item found with data-value: ${value}`);
  }
}

export function addDropdownItem(value, innerHTML = value) {
  if (value === undefined || value == null) {
    throw new Error("Dropdown items must have a valid non-null value!")
  }

  const dropdownItem = document.createElement("div");
  dropdownItem.classList.add("gecko-select-dropdown-item");
  dropdownItem.setAttribute("data-value", value);
  dropdownItem.setAttribute("x-on:click", "handleSelect");
  dropdownItem.innerHTML = innerHTML;


  const currentValue = this.value;
  const newItemValue = String(value);

  // We use `.includes` for multi-select component, as it keeps currently selected values as an array.
  if (currentValue === newItemValue || (Array.isArray(currentValue) && currentValue.includes(newItemValue))) {
    dropdownItem.innerHTML += '<i class="fa fa-check gecko-select-selected"></i>';
  }

  return this._gp_refDropdownResults.appendChild(dropdownItem);
}

export function clearDropdownItems() {
  this._gp_refDropdownLoading.classList.add("tw-hidden");
  this._gp_refDropdownMessage.classList.add("tw-hidden");

  this._gp_refDropdownResults.classList.remove("tw-hidden");
  this._gp_refDropdownResults.innerHTML = "";
}

export function showDropdownMessage(message) {
  this._gp_refDropdownLoading.classList.add("tw-hidden");
  this._gp_refDropdownMessage.classList.remove("tw-hidden");

  if (message) {
    this._gp_refDropdownMessage.innerText = message;
  }
}

export function addDropdownDivider(title) {
  if (title === undefined || title == null) {
    throw new Error("Dropdown divider title must have a valid non-null value!")
  }

  const dropdownDivider = document.createElement("div");
  dropdownDivider.classList.add("gecko-select-dropdown-divider");
  dropdownDivider.setAttribute("role", "presentation");
  dropdownDivider.innerHTML = `
      <div class="tw-my-2 tw-mx-1.5 tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-sm">
        <span class="tw-shrink-0 tw-basis-auto">${title}</span>
        <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
      </div>
    `;

  return this._gp_refDropdownResults.appendChild(dropdownDivider);

}
