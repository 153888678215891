import {
  showDropdownMessage,
  addDropdownItem,
  clearDropdownItems,
  addDropdownDivider
} from "./utils";

export default {
  _prepareDynamicCombo() {
    const dropdownStaticTarget = this._gp_refDropdownStatic;
    const dropdownResultsTarget = this._gp_refDropdownResults;
    const dropdownMessageTarget = this._gp_refDropdownMessage;
    const dropdownLoadingTarget = this._gp_refDropdownLoading;

    this._gp_refTextInput.addEventListener("input", (e) => {
      this.open = true;

      const query = e.currentTarget.value;
      if (!query) {
        dropdownResultsTarget.classList.add("tw-hidden");
        dropdownLoadingTarget.classList.add("tw-hidden");

        if (dropdownStaticTarget) {
          dropdownStaticTarget.classList.remove("tw-hidden");
          dropdownMessageTarget.classList.add("tw-hidden");
        } else {
          dropdownMessageTarget.classList.remove("tw-hidden");
        }

        return;
      }


      dropdownStaticTarget?.classList?.add("tw-hidden");
      dropdownResultsTarget.classList.add("tw-hidden");
      dropdownMessageTarget.classList.add("tw-hidden");
      dropdownLoadingTarget.classList.remove("tw-hidden");

      this.$refs.input.dispatchEvent(new CustomEvent("search", {
        detail: {
          query: query,
          showDropdownMessage: showDropdownMessage.bind(this),
          addDropdownItem: addDropdownItem.bind(this),
          clearDropdownItems: clearDropdownItems.bind(this),
          addDropdownDivider: addDropdownDivider.bind(this),
        }
      }));
    });
  },
};
