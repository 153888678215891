import { Controller } from "stimulus";
import { getAdData } from "../../../util/kevel";
import { throttle } from "../../../util";
import { unconditionalTrackEvent } from "../../../analytics";

export default class extends Controller {
  async loadAdData() {
    this.adData = await getAdData(this.adProp.requestBody);
  }

  adDataNotNull() {
    return this.adData[0].link !== null;
  }

  getAdProp() {
    return JSON.parse(this.bodyTarget.dataset.adProp);
  }

  adDataNotLoaded() {
    return !this.adData;
  }

  viewportAd(){
    return this.bodyTarget.dataset.viewportAd === "true";
  }

  loadAdIfAdAtViewport() {
    const lazyLoad = () => {
      if (
        this.bodyTarget.getBoundingClientRect().top <= window.innerHeight &&
        this.bodyTarget.getBoundingClientRect().bottom >= 0
      ) {
        this.sendImpression();
      }
    };

    // add throttle here to prevent scroll event recurring
    document.addEventListener("scroll", throttle(lazyLoad, 500));
    window.addEventListener("orientationchange", throttle(lazyLoad, 500));
  }

  attachKeywordToRequestBody() {
    this.adProp.requestBody.keywords = [this.bodyTarget.dataset.keyword];
  }

  dataKeywordExist() {
    return this.bodyTarget.dataset.keyword !== undefined;
  }

  unrenderedAdDiv(element) {
    return element?.querySelector(".fa-spin");
  }

  trackEvent() {
    if (this.bodyTarget.dataset.analyticsData) {
      const analyticsData = JSON.parse(this.bodyTarget.dataset.analyticsData);
      unconditionalTrackEvent(analyticsData.event, analyticsData.event_properties);
    }
  }

  injectEventDataToAdLink(targetBody) {
    if (targetBody.dataset.analyticsChildrenData) {
      const analyticsData = JSON.parse(targetBody.dataset.analyticsChildrenData);

      targetBody.querySelectorAll("[data-analytics-event][data-analytics-event-properties]").forEach(element => {
        const existingProperties = element.getAttribute("data-analytics-event-properties");
        const existingData = existingProperties ? JSON.parse(existingProperties) : {};
        const mergedProperties = { ...existingData, ...analyticsData.event_properties };

        element.setAttribute("data-analytics-event", analyticsData.event);
        element.setAttribute("data-analytics-event-properties", JSON.stringify(mergedProperties));
      });
    }
  }
}
