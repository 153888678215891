import { formatCurrency } from "../util/currency";
import { SELECT_SEARCH_EVENT, SELECT_SEARCH_HIGHLIGHTS } from "../mixpanel_config";
import { searchCategoryEventProperties } from "../analytics";

// GT Templates
const gtTokensCustomTemplate = (imgSrc, imgNetworkSrc, name, liquidity, tokenUrl, data) => {
  let img = ""
  let imgNetwork = ""
  let liquidityFormatted = formatCurrency(liquidity, "usd", false, false, true);

  if (imgSrc) {
    img = `<img src="${imgSrc}" class="tw-w-6 tw-mr-2" loading="lazy"/>`
  }

  if (imgNetworkSrc) {
    imgNetwork = `<img src="${imgNetworkSrc}" class="tw-w-6 tw-mr-2" loading="lazy"/>`
  }

  return `
    <div data-smart-result='${data}' data-type="GT" class="results-item" data-action="click->analytics-tracker#trackEvent mouseenter->search-v2#renderSmartResult mouseenter->search-v2#addSelectIconToResult"
    data-analytics-event="${SELECT_SEARCH_EVENT}" data-analytics-event-properties='${JSON.stringify(searchCategoryEventProperties('geckoterminal'))}'>
      <a href="${tokenUrl}" target="_blank" class="tw-flex tw-justify-between tw-px-2 tw-py-3 tw-rounded-lg">
        <div class="tw-flex tw-items-center">
          ${img}
          ${imgNetwork}
          <div class="tw-text-gray-700 tw-text-sm tw-font-semibold hover:tw-text-gray-900 dark:tw-text-moon-100  dark:hover:tw-text-moon-50 tw-mr-1">${name}</div>
          <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-regular tw-flex-grow">${I18n.t("search.liquidity")}: ${liquidityFormatted}</div>
        </div>
        <div class="tw-flex tw-items-center" id="rightText">
          <i class="fas fa-external-link dark:tw-opacity-60 tw-w-6"></i>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-hidden" id="rightTextButton">
          <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium">
            ${I18n.t("search.select")}
          </div>
          <i class="tw-inline-flex tw-items-center tw-rounded-md  tw-bg-gray-400 dark:tw-bg-moon-500 tw-px-2 tw- py-0.5
            tw-text-xs tw-leading-4 tw-text-white  dark:tw-text-moon-50 tw-font-medium fa-regular fa-arrow-turn-down-left">
          </i>
        </div>
      </a>
    </div>`;
}

export const getGeckoTerminalTokensTemplate = (geckoterminal_tokens, hasScore = false, url) => {
  if (geckoterminal_tokens && geckoterminal_tokens.length) {
    const geckoTerminalTokensTemplate = geckoterminal_tokens
      .map((token, _idx) => {
        let urlPath = new URL(url)
        let tokenUrl = token.url.concat("?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults")
        urlPath.searchParams.set("id", token.name)
        urlPath.searchParams.set("type", "geckoterminal_token")
        return gtTokensCustomTemplate(
          token.base_token_logo_url,
          token.network_logo_url,
          token.name,
          token.liquidity,
          tokenUrl,
          JSON.stringify(token));
      })
      .join("");
    return `
      <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-gt-results">
        <span class="tw-shrink-0 tw-basis-auto">${I18n.t("search.geckoterminal")}</span>
        <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
      </div>
      <div class="list-reset relevant-results">
        ${geckoTerminalTokensTemplate}
      </div>`;
  }
  return ``;
};

export const getCompareCoinsTemplate = (coin, searchInput, { onInputDisplay = false, selected = false } = {}) => {
  const coinDataToShow = onInputDisplay ? formatCurrency(coin.price, "usd") : `(${coin.symbol.toUpperCase()})`
  const selectedIcon = !onInputDisplay && selected ? '<i class="fa fa-check gecko-select-selected"></i>' : '';

  return `
      <div class="tw-flex tw-items-center tw-gap-x-2"
      data-search-input="${searchInput}"
      data-coin='${JSON.stringify(coin)}'
      >
        <img class="tw-h-6 tw-w-6" src="${coin.image}" alt="${coin.name}"/>
        ${coin.name} ${coinDataToShow}
      </div>
      ${selectedIcon}
    `;
}

export const getCompareNftsTemplate = (nft, searchInput, { onInputDisplay = false, selected = false } = {}) => {
  const selectedIcon = !onInputDisplay && selected ? '<i class="fa fa-check gecko-select-selected"></i>' : '';
  const nftDataToShow = onInputDisplay ? formatCurrency(nft.floorPriceNative, nft.nativeCurrencySymbol) : '';

  return `
      <div class="tw-flex tw-items-center tw-gap-x-2"
      data-search-input="${searchInput}"
      data-nft='${JSON.stringify(nft)}'
      >
        <img class="tw-h-6 tw-w-6 tw-rounded-full" src="${nft.image}" alt="${nft.contractName}"/>
        ${nft.contractName} ${nftDataToShow}
      </div>
      ${selectedIcon}
    `;
}