import { AD_INTEREST_KEYS, USER_UUID_KEY } from "../config";

const KEVEL_ASSET_URL = "https://s.zkcdn.net/Advertisers/";
const GECKAD_ASSET_URL = "https://assets.geckad.com/";
const USER_DB_URL = "https://geckad.com/kevel/userdb/";

export const getAdData = async (requestBody) => {
  const endpoint = document.body.dataset.kevelApiProxyEndpoint;

  // add local uuid if user not logged in
  if (!!requestBody.user === false) {
    requestBody.user = { key: localStorage.getItem(USER_UUID_KEY) };
  }

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(requestBody),
  };

  const response = await fetch(endpoint, requestOptions);
  const data = await response.json();
  const adData = data.decisions.multiWinner;

  if (adData == null) {
    console.error("kevel: can't find ad or ad not targeting this page");
    return [{
      link: null,
      impressionLink: null,
      adContentData: null,
      flightId: null,
    }];
  } else {
    return adData.map(data => {
      const adContentData = data.contents[0].data;
      return {
        link: data.clickUrl,
        impressionLink: data.impressionUrl,
        adContentData: adContentData,
        flightId: data.flightId,
      };
    });
  };
};

export const sendImpression = async (endpoint) => {
  const response = await fetch(endpoint);

  if (response.status === 200) {
    return true;
  } else {
    console.error("Error sending impression!");
    return false;
  }
};

export const replaceAssetURL = (url) => {
  if (url !== undefined) {
    return url.replace(KEVEL_ASSET_URL, GECKAD_ASSET_URL);
  }
};

export const syncUserDb = async () => {
  const body = buildInterestDataBody();
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
  };

  const response = await fetch(USER_DB_URL, requestOptions);
  const responseBody = await response.text();

  if (response.status === 200) {
    return true;
  } else {
    console.error(responseBody);
    return false;
  }
};

const buildInterestDataBody = () => {
  const uuid = localStorage.getItem(USER_UUID_KEY);
  let interestData = {};

  for (const key in AD_INTEREST_KEYS) {
    const data = localStorage.getItem(AD_INTEREST_KEYS[key]);
    if (!!data) {
      interestData[key] = JSON.parse(data);
    }
  }

  return { uuid: uuid, interest_data: interestData };
};

